import React, { useEffect } from "react";
import { useState } from "react";
import "./MainCarousel.scss";
import { constants } from "../../redux/constants";
import { updateShopObject } from "../../redux/actions/shopActions";
import { useDispatch } from "react-redux";
import { CardGroup, Carousel, Col, Container, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import MainCarouselCard from "./MainCarouselCard";
import MainCarouselTitle from "./MainCarouselTitle";
import MainCarouselFancyTitle from "./MainCarouselFancyTitle";
import MainCarouselTitleDescription from "./MainCarouselTitleDescription";
import MainCarouselSubtitle from "./MainCarouselSubtitle";
import MainCarouselSubtitleDescription from "./MainCarouselSubtitleDescription";
import MainCarouselPriceBox from "./MainCarouselPriceBox";
import MainCarouselPlansButton from "./MainCarouselPlansButton";
import { goTo } from "../../utilities/utils";
import styled from "styled-components";
import { useI18next } from "gatsby-plugin-react-i18next";
import { Trans, useTranslation } from "react-i18next";

interface MainCarouselProps {
  currentIndex: number;
  setIndex: Function;
}

const MainCarousel: React.FC<MainCarouselProps> = (
  props: MainCarouselProps
) => {
  const dispatch = useDispatch();
  const handleSelect = (selectedIndex) => {
    setCountry(selectedIndex);
  };

  const { language } = useI18next();
  const { t } = useTranslation();
  const isFrench = language === "fr";
  const isEnglish = language === "en";

  const setCountry = (index) => {
    switch (index) {
      case 0:
      case 1:
        window.localStorage.setItem("country", constants.CANADA);
        dispatch(updateShopObject({ country: constants.CANADA }) as any);
        dispatch(
          updateShopObject({ currency: constants.CANADA_CURRENCY }) as any
        );
        break;
      case 2:
        window.localStorage.setItem("country", constants.USA);
        dispatch(updateShopObject({ country: constants.USA }) as any);
        dispatch(updateShopObject({ currency: constants.USA_CURRENCY }) as any);
        break;
    }
    props.setIndex(index);
  };

  const [mobile, setMobile] = useState(false);
  useEffect(() => {
    const handleWindowResize = () => {
      if (window.innerWidth < 992) {
        setMobile(true);
      } else setMobile(false);
    };
    handleWindowResize();
    window.addEventListener("resize", handleWindowResize);

    return () => window.removeEventListener("resize", handleWindowResize);
  }, [mobile]);

  const nextIcon = () => {
    return mobile ? null : (
      <span className="mc-indicator">
        <FontAwesomeIcon icon={faChevronRight} size="lg" />
      </span>
    );
  };

  const prevIcon = () => {
    return mobile ? null : (
      <span className="mc-indicator">
        <FontAwesomeIcon icon={faChevronLeft} size="lg" />
      </span>
    );
  };

  const getImageLanguage = () => {
    if (language === "en") {
      return "/images/mc/01-03-23/en.png";
    } else if (language === "fr") {
      return "/images/mc/01-03-23/fr.png";
    } else if (language === "sp") {
      return "/images/mc/01-03-23/sp.png";
    }
  };

  return (
    <>
      <Carousel
        nextIcon={nextIcon()}
        prevIcon={prevIcon()}
        interval={null}
        className="mc"
        activeIndex={props.currentIndex}
        indicators={mobile}
        onSelect={handleSelect}
      >
        <Carousel.Item className="mc-item mc-image-phonebox">
          <Container className="mc-item-container" fluid={true}>
            <Row>
              <Col sm={5} lg={5}>
                <MainCarouselTitle text="PhoneBox" />
                <MainCarouselTitleDescription
                  text="Whatever the purpose of your stay or the duration, all needs are covered by hero plans, "
                  textBold="tailor-made for all budgets!"
                />
              </Col>
              <Col sm={7} lg={7}>
                <MainCarouselFancyTitle text="Stay connected with no boundaries" />
                <CardGroup className="mc-card-group mc-hide-content-mobile">
                  <MainCarouselCard
                    imageUrl={
                      language === "en"
                        ? "/images/mc/canada-plan.png"
                        : "/images/mc/mc_canada_plans.png"
                    }
                    titleText={language === "en" ? "" : "Canadian Plans"}
                    buttonText="Visit"
                    style={{ marginRight: "10px" }}
                    onClick={() => {
                      setCountry(1);
                      goTo("/plans");
                    }}
                  />
                  <MainCarouselCard
                    imageUrl={
                      language === "en"
                        ? "/images/mc/usa-plan.png"
                        : "/images/mc/mc_american_plans.png"
                    }
                    titleText={language === "en" ? "" : "American Plans"}
                    buttonText="Visit"
                    style={{ marginLeft: "10px" }}
                    onClick={() => {
                      setCountry(2);
                      goTo("/plans");
                    }}
                  />
                </CardGroup>
              </Col>
            </Row>
          </Container>
        </Carousel.Item>
        <Carousel.Item className="mc-item mc-image-canada">
          <Container className="mc-item-container" fluid={true}>
            <Row>
              <Col sm={5} lg={4}>
                <MainCarouselTitle text="Canada" />
                <MainCarouselSubtitle text="Explore True North" />
                <MainCarouselSubtitleDescription text="Fastest Network with Affordable Plans" />
                <MainCarouselPriceBox text="Plans From" price={9} />
                <MainCarouselPlansButton
                  className="mc-hide-content-mobile"
                  text="See Plans"
                  onClick={() => {
                    setCountry(1);
                    goTo("/plans");
                  }}
                />
              </Col>
              <Col sm={7} lg={8}>
                <MainCarouselFancyTitle text="Stay connected with no boundaries" />
                <CardGroup className="mc-card-group mc-hide-content-mobile">
                  <MainCarouselCard
                    imageUrl={`/images/mc/24-mar-25/new-offer.png`}
                    titleText=""
                    onClick={() => {
                      goTo("/plans");
                    }}
                    buttonText="Get It Now!"
                  />
                  <MainCarouselCard
                    showDeals={true}
                    imageUrl={`/images/mc/24-mar-25/home-internet.png`}
                    titleText=""
                    showConnectInAFlash
                    onClick={() => {
                      goTo("/home-internet");
                    }}
                    style={{ marginLeft: "10px", marginRight: "10px" }}
                    buttonText="Check More"
                  />
                  <MainCarouselCard
                    imageUrl={`/images/mc/24-mar-25/self-serve.png`}
                    titleText=""
                    showPrepaidPlans
                    onClick={() => {
                      goTo("/pbnewapp");
                    }}
                    buttonText="Explore Now"
                  />
                </CardGroup>
              </Col>
            </Row>
          </Container>
        </Carousel.Item>
        <Carousel.Item className="mc-item mc-image-usa">
          <Container className="mc-item-container" fluid={true}>
            <Row>
              <Col sm={5} lg={4}>
                <MainCarouselTitle text="USA" />
                <MainCarouselSubtitle text="The best network" />
                <MainCarouselSubtitleDescription text="No worries about overages with prepaid plan" />
                <MainCarouselPriceBox text="Plans From" price={8} />
                <MainCarouselPlansButton
                  className="mc-hide-content-mobile"
                  text="See Plans"
                  onClick={() => {
                    setCountry(2);
                    goTo("/plans");
                  }}
                />
              </Col>
              <Col sm={7} lg={8}>
                <MainCarouselFancyTitle text="Stay connected with no boundaries" />
                <CardGroup className="mc-card-group mc-hide-content-mobile">
                  <MainCarouselCard
                    imageUrl={
                      language === "en"
                        ? "/images/mc/us-poster-new-4.png"
                        : "/images/mc/us-poster-new.png"
                    }
                    titleText={language === "en" ? "" : "New Features"}
                    // subtitleText="Extra Calling Power! Add 500 minutes or Unlimited International Calls to your plan!"
                    subtitleComponent={
                      language === "en" ? null : (
                        <div>
                          <p
                            style={{
                              fontWeight: 500,
                              paddingTop: "8px",
                              fontSize: "20px",
                            }}
                            className="m-0 px-3 text-white"
                          >
                            <Trans>Extra Calling Power!</Trans>
                          </p>
                          <div
                            style={{
                              height: "1px",
                              width: "160px",
                              margin: "10px auto 16px",
                              backgroundColor: "#ffffff",
                              borderRadius: "4px",
                            }}
                          ></div>
                          <p
                            style={{
                              color: "#ffffff",
                              fontSize: "13px",
                              width: "170px",
                              textAlign: "center",
                              margin: "auto",
                            }}
                          >
                            <Trans>
                              Add 500 minutes or Unlimited International Calls
                              to your plan!
                            </Trans>
                          </p>
                        </div>
                      )
                    }
                    buttonText="Know More"
                    onClick={() => {
                      goTo(
                        "https://blog.gophonebox.com/all-about-our-us-international-calls-feature/"
                      );
                    }}
                    style={{ backgroundColor: "rgba(0,0,0,0)" }}
                  />
                  <MainCarouselCard
                    style={{
                      marginLeft: "10px",
                      marginRight: "10px",
                      backgroundColor: "rgba(0,0,0,0)",
                    }}
                    imageUrl={
                      language === "en"
                        ? "/images/mc/us-poster-new-6.png"
                        : "/images/mc/us-poster-new-5.png"
                    }
                    titleText={language === "en" ? "" : "Budget Bliss!"}
                    // subtitleText={"Unlock 5GB for just $15 for 30 days! —Affordable Connectivity!"}
                    subtitleComponent={
                      language === "en" ? null : (
                        <div>
                          <p
                            style={{
                              fontWeight: "600",
                              fontSize: "16px",
                              color: "#ffffff",
                              marginTop: 8,
                            }}
                          >
                            <Trans>Affordable Connectivity!</Trans>
                          </p>
                          <div
                            style={{
                              borderRadius: "16px",
                              background:
                                "linear-gradient(180deg, #83bf69 -30.21%, #335625 139.58%)",
                              width: "180px",
                              margin: "auto",
                              padding: "10px",
                            }}
                          >
                            <p
                              style={{
                                fontSize: "12px",
                                fontWeight: "600",
                                color: "#ffffff",
                                margin: 0,
                              }}
                            >
                              <span
                                style={{ fontWeight: "700", fontSize: "16px" }}
                              >
                                5 GB{" "}
                              </span>
                              <Trans>for just</Trans>
                            </p>
                            <p
                              style={{
                                color: "#ffffff",
                                fontWeight: "bold",
                                fontSize: "16px",
                                marginTop: "8px",
                                marginBottom: 0,
                              }}
                            >
                              <span
                                style={{
                                  lineHeight: 1,
                                  color: "#fff",
                                  fontSize: "48px",
                                  fontWeight: "600",
                                }}
                              >
                                $15
                              </span>
                              /30 <Trans>days</Trans>
                            </p>
                          </div>
                        </div>
                      )
                    }
                    buttonText="I want it!"
                    onClick={() => {
                      goTo("/activation");
                    }}
                  />
                  <MainCarouselCard
                    imageUrl={
                      language === "en"
                        ? "/images/mc/us-poster-new-7.png"
                        : "/images/mc/us-poster-new-3.png"
                    }
                    titleText={
                      language === "en" ? "" : "Calling all travelers!"
                    }
                    // subtitleText="Calling all travelers! New 7 and 15 Day Plan!"
                    subtitleComponent={
                      language === "en" ? null : (
                        <div>
                          <p
                            style={{
                              fontWeight: "bold",
                              paddingTop: "8px",
                              fontSize: "14px",
                              color: "#223919",
                            }}
                            className="m-0 px-3"
                          >
                            <Trans>New 7 and 15 Day Plan!</Trans>
                          </p>
                          <div
                            style={{
                              backgroundColor: "#223919",
                              width: "188px",
                              padding: "10px 8px",
                              margin: "18px auto",
                              borderRadius: "12px",
                            }}
                          >
                            <span
                              style={{
                                color: "#223919",
                                fontSize: "14px",
                                fontWeight: "bold",
                                padding: "0 12px",
                                backgroundColor: "#fff",
                                borderRadius: "6px",
                                margin: "auto",
                              }}
                            >
                              3GB
                            </span>
                            <p
                              style={{
                                color: "#ffffff",
                                fontWeight: "bold",
                                fontSize: "16px",
                                marginTop: "8px",
                                marginBottom: 0,
                              }}
                            >
                              <span
                                style={{
                                  lineHeight: 1,
                                  color: "#fff",
                                  fontSize: "48px",
                                  fontWeight: "600",
                                }}
                              >
                                $8
                              </span>
                              /7 <Trans>days</Trans>
                            </p>
                          </div>
                        </div>
                      )
                    }
                    buttonText="View Plans"
                    style={{ backgroundColor: "rgba(0,0,0,0)" }}
                    onClick={() => {
                      setCountry(2);
                      goTo("/plans");
                    }}
                  />
                </CardGroup>
              </Col>
            </Row>
          </Container>
        </Carousel.Item>
      </Carousel>
    </>
  );
};

export default MainCarousel;

const OurButton = styled.div<{ color: string; width: string }>`
  border-style: solid;
  border-radius: 10px;
  border-color: ${(props) => (props.color === "" ? "#fff" : props.color)};
  border-width: 1px;
  text-decoration: none;
  padding: 5px;
  text-align: center;
  color: #fff;
  background-color: ${(props) => (props.color ? props.color : "rgba(0,0,0,0)")};
  width: ${(props) => props.width};
  height: fit-content;
  padding-left: 10px;
  padding-right: 10px;
  display: block;
  font-weight: 600;
  cursor: pointer;
`;
